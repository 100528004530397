 

$base_color: hsl(153, 48%, 46%);

$base_line_color: rgb(219, 221, 220);

.h_line {
  width: 100%;
  height: 1px;
  background-color: $base_line_color;
}

.v_line {
  width: 1px;
  height: 100%;
  background-color: $base_line_color;
}

$base_circle_color:hsl(168, 4%, 74%);

@mixin circle($size:2rem,$color:$base_circle_color) {
  width: $size;
  height: $size;
 
  background-color: $color;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
