@import "../base.scss";

.main0 {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  
  box-sizing: border-box;
}
.main {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.year_main {
  display: flex;
}
.year {
  padding: 0.1rem;
  font-size: 2.2rem;
  display: flex;
  width: 100%;
  align-items: center;
}
.time_year_line {
  width: 0.5rem;
  height: 60%;
  margin-top: 55%;
  box-sizing: border-box;
  background-color: $base_line_color;
}
.time_year_main {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 5rem;
  position: relative;
  .cirlce {
    @include circle(1.5rem);
    position: absolute;
    margin-top: -0.75rem;
    margin-left: -0.75rem;
    top: 50%;
    bottom: 50%;
    left: 50%;
    right: 50%;
    z-index: 2;
  }
}

.mouse_enter_color {
  color: $base_color;
}
.mouse_enter_bg_color {
  background-color: $base_color !important;
}

.title_main {
  display: flex;

  .title {
    font-size: 1.5rem;
    margin-top: 2.5rem;
    width: 100%;
    margin-bottom: 2rem;
    box-sizing: border-box;
    cursor: pointer;
  }
  .h_line {
    @extend .h_line;
    margin-top: 1rem;
  }
}
.time_v_line_title {
  background-color: $base_line_color;
  width: 0.5rem;
  height: 100%;
}

.time_v_line_title_last {
  background-color: $base_line_color;
  width: 0.5rem;
  height: 60%;
  margin-bottom: 85%;
  box-sizing: border-box;
}

.time_v_main_title {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5rem;
  position: relative;
  .cirlce {
    @include circle(1rem);
    position: absolute;
    margin-top: -0.5rem;
    margin-left: -0.5rem;
    top: 50%;
    bottom: 50%;
    left: 50%;
    right: 50%;
    z-index: 2;
  }
}

.title_content {
  display: flex;
  align-items: center;
  .time {
    font-size: 1rem;
  }
  .title1 {
    flex: 1;

    margin-left: 1.8rem;
 
  }
}
