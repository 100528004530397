.main {
  display: flex;
  flex-direction: column;
  
  height: 100%;
  position: relative;
}

.top_main{
  position: fixed;
  width: 100%;
  background-color: white;
  top: 0px;
	z-index:9999;
}

 
.content_main{
  height: 100%;
  box-sizing: border-box;
}